export default {
  name: "PageForm",
  components: {},
  data() {
    return {
      windowWidth: window.innerWidth,

      sended: false,
      sending: false,

      invalid: false,

      name: "",
      phone: "",
      email: "",
      note: "",
    };
  },
  methods: {
    validate() {
      if (
        this.name.length == 0 ||
        this.phone.length == 0 ||
        this.email.length == 0
      ) {
        this.invalid = true;
      } else {
        this.invalid = false;
      }

      console.warn({
        name: this.name,
        phone: this.phone,
        email: this.email,
        note: this.note,
        items: this.getItems(),
        invalid: this.invalid,
      });

      return !this.invalid;
    },
    onResize() {
      this.windowWidth = window.innerWidth;

      // console.warn(window.innerWidth);

      if (window.innerWidth >= 500) {
        this.$store.commit("loadingSave", false);
      }
    },
    send() {
      if (!this.validate()) {
        return;
      }

      // console.warn("valid");

      this.sending = true;

      this.axios
        .post("https://www.virtualexhibition.o-ran.org/api/Form/KomatsuForm", {
          name: this.name,
          phone: this.phone,
          email: this.email,
          note: this.note,
          items: this.getItems(),
        })
        .then((response) => {
          console.warn(response);

          this.sending = false;
          this.sended = true;

          this.$store.commit("selectSave", []);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    goBack() {
      this.$router.go(-1);
    },
    getItems() {
      return this.$store.getters.getSelect
        .map((x) => {
          return x.name;
        })
        .join(", ");
    },
  },
  created() {},
};
